import InfoIcon from '@mui/icons-material/InfoOutlined'

export function JobSearchDropdownFooter() {
  return (
    <div
      className='border px-4 py-3'
      style={{
        backgroundColor: 'white',
        margin: '16px',
        borderRadius: '16px',
        borderColor: '#7EC4F6',
        borderWidth: '1px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box'
      }}
    >
      <div className='flex items-center gap-2'>
        <InfoIcon
          className='w-4 h-4'
          style={{
            color: '#7EC4F6'
          }}
        />
        <span
          className='text-sm'
          style={{
            color: '#1A1A1A',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '17px',
            textAlign: 'left'
          }}
        >
          Choose a job title to view details about the occupation it belongs to.
        </span>
      </div>
    </div>
  )
}
