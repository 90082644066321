import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const highlightMatch = (text, query) => {
  if (!query) return text
  const escapedQuery = escapeRegExp(query)
  const parts = text.split(new RegExp(`(${escapedQuery})`, 'gi'))
  return parts.map((part, index) =>
    part.toLowerCase() === query.toLowerCase() ? (
      <span
        key={index}
        className='font-bold text-sm text-[#1D4289] leading-[17px] decoration-solid'
        style={{
          textDecorationSkipInk: 'none',
          textAlign: 'left'
        }}
      >
        {part}
      </span>
    ) : (
      part
    )
  )
}

const TruncatedTitle = ({
  text,
  maxLength = 40,
  className = '',
  query = '',
  shouldHighlight = false
}) => {
  const needsTruncation = text.length > maxLength
  const truncatedText = needsTruncation
    ? `${text.substring(0, maxLength)}...`
    : text
  const highlightedText = shouldHighlight
    ? highlightMatch(truncatedText.trim(), query.trim())
    : truncatedText

  return needsTruncation ? (
    <Tooltip
      title={
        <Typography
          variant='body2'
          sx={{ whiteSpace: 'normal', maxWidth: 300 }}
        >
          {text}
        </Typography>
      }
      arrow
      placement='top'
    >
      <span className={`${className} block truncate cursor-pointer`}>
        {highlightedText}
      </span>
    </Tooltip>
  ) : (
    <span className={className}>{highlightedText}</span>
  )
}

export default TruncatedTitle
