import { useEffect, useRef } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import TruncatedTitle from 'src/components/TruncatedTitle'

export function VirtualizedJobSearchDropdown({
  selectedOptions,
  handleOptionClick,
  inputValue,
  loadMoreAlternativeTitles,
  isLoading
}: any) {
  const parentRef = useRef(null)
  const containerRef = useRef(null)

  const rowVirtualizer = useVirtualizer({
    count: selectedOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 20,
    overscan: 5
  })

  useEffect(() => {
    const scrollElement = parentRef.current
    if (!scrollElement) return

    const handleScroll = () => {
      if (isLoading) return

      const { scrollTop, scrollHeight, clientHeight } = scrollElement

      if (scrollHeight - scrollTop - clientHeight < 100) {
        loadMoreAlternativeTitles()
      }
    }

    ;(scrollElement as HTMLElement).addEventListener('scroll', handleScroll)
    return () =>
      (scrollElement as HTMLElement).removeEventListener('scroll', handleScroll)
  }, [isLoading, loadMoreAlternativeTitles])

  return (
    <div className='flex flex-col'>
      {/* Fixed Header */}
      {selectedOptions.length > 0 && (
        <div className='flex items-center justify-between w-full gap-4 px-6 py-3 bg-white border-b'>
          <div className='flex-1 min-w-0'>
            <span className='text-[14px] font-semibold text-left text-[#000]'>
              Job Title
            </span>
          </div>

          <div className='flex-1 min-w-0 text-left'>
            <span className='text-[14px] font-semibold text-left text-[#000]'>
              Occupation
            </span>
          </div>
        </div>
      )}

      <div
        ref={parentRef}
        className='px-4 max-h-[200px] overflow-y-auto'
        style={{ paddingBottom: '4px', paddingTop: '8px' }}
      >
        {selectedOptions.length === 0 ? (
          <div className='text-gray-500'>No results found</div>
        ) : (
          <div>
            <div
              ref={containerRef}
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: '100%',
                position: 'relative'
              }}
            >
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const option = selectedOptions[virtualRow.index]
                return (
                  <div
                    key={virtualRow.index}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`
                    }}
                  >
                    <div
                      className='hover:bg-gray-100 cursor-pointer rounded-lg px-2'
                      onClick={() => handleOptionClick(option)}
                    >
                      <div className='flex items-center justify-between w-full gap-4'>
                        <div className='flex-1 min-w-0'>
                          <TruncatedTitle
                            text={option?.alternative_title}
                            shouldHighlight
                            query={inputValue}
                            className='text-[14px] font-normal leading-[17px] text-left text-[#1D4289]'
                          />
                        </div>

                        <div className='flex-1 min-w-0 text-left'>
                          <TruncatedTitle
                            text={option.occ2010_title}
                            className='text-[14px] font-normal leading-[17px] text-[#666666]'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {isLoading && (
          <div className='text-center py-2 text-gray-500'>Loading more...</div>
        )}
      </div>
    </div>
  )
}

export default VirtualizedJobSearchDropdown
