import { JobSearchDropdownFooter } from './JobSearchDropdownFooter'
import VirtualizedJobSearchDropdown from './JobSearchDropdownOptions'

export function JobSearchDropdown({
  open,
  dropdownRef,
  selectedOptions,
  handleOptionClick,
  inputValue,
  handleLoadMore,
  isLoading
}: any) {
  return (
    open && (
      <div
        ref={dropdownRef}
        className='absolute w-full sm:w-[639px] mt-3 bg-white rounded-lg shadow-lg border border-gray-200 z-40'
      >
        <VirtualizedJobSearchDropdown
          selectedOptions={selectedOptions}
          handleOptionClick={handleOptionClick}
          inputValue={inputValue}
          loadMoreAlternativeTitles={handleLoadMore}
          loadMoreTitles={handleLoadMore}
          isLoading={isLoading}
        />

        <JobSearchDropdownFooter />
      </div>
    )
  )
}
