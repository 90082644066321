import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback
} from 'react'
import type { JobTitle } from './useAlternativeJobTitles'
import useFetchWorkers from './useFetchWorkers'

interface SelectedJobContextData {
  selectedJob: JobTitle | null
  setSelectedJob: (job: JobTitle | null) => void
  handleFetchJobWorkers: (
    regionCode: string | number,
    occCode: number
  ) => Promise<void>
  handleChangeSelectedJobLoading: (isLoading: boolean) => void
  fetchWorkersLoading: boolean
  selectedJobLoading: boolean
  jobWorker: JobWorker
}

interface SelectedJobProviderProps {
  children: ReactNode
}

interface JobWorker {
  total_workers: number
  stars_population: number
}

const SelectedJobContext = createContext<SelectedJobContextData>(
  {} as SelectedJobContextData
)

export function SelectedJobProvider({ children }: SelectedJobProviderProps) {
  const { fetchJobWorkersData } = useFetchWorkers()
  const [jobWorker, setJobWorker] = useState<JobWorker>({
    stars_population: 0,
    total_workers: 0
  })
  const [selectedJobLoading, setSelectedJobLoading] = useState(false)
  const [fetchWorkersLoading, setFetchWorkersLoading] = useState(false)

  const [selectedJob, setSelectedJob] = useState<JobTitle | null>({
    occ2010_title: 'Chief Executives & Legislators',
    occ2010: '10',
    total_postings: 100
  })

  const handleFetchJobWorkers = async (
    regionCode: string | number,
    occCode: number
  ) => {
    try {
      setFetchWorkersLoading(true)
      const response = await fetchJobWorkersData(regionCode, occCode)
      setJobWorker({
        stars_population: response.stars_population,
        total_workers: response.total_workers
      })
      setFetchWorkersLoading(false)
    } catch (error) {
      setFetchWorkersLoading(false)
    }
  }

  const handleChangeSelectedJobLoading = useCallback((isLoading: boolean) => {
    setSelectedJobLoading(isLoading)
  }, [])

  return (
    <SelectedJobContext.Provider
      value={{
        selectedJob,
        setSelectedJob,
        handleFetchJobWorkers,
        handleChangeSelectedJobLoading,
        selectedJobLoading,
        jobWorker,
        fetchWorkersLoading
      }}
    >
      {children}
    </SelectedJobContext.Provider>
  )
}

export function useSelectedJob() {
  const context = useContext(SelectedJobContext)

  if (!context) {
    throw new Error('useSelectedJob must be used within a SelectedJobProvider')
  }

  return context
}
