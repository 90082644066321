import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useSelectedJob } from './useSelectedJob'

export interface AlternativeJobTitle {
  id: number
  open_roles: number
  alternative_title: string
  soc5: string
  occ2010: string | null
  occ2010_title: string | null
}

export interface JobTitle {
  occ2010_title: string
  occ2010: string | null
  total_postings?: number
}

interface JobTitlesState {
  alternativeTitles: {
    data: {
      results: AlternativeJobTitle[]
      total_matches: number
    }
    loading: boolean
    error: string | null
    hasMore: boolean
    currentPage: number
  }
}

interface UseJobTitlesReturn extends JobTitlesState {
  loadMoreAlternativeTitles: () => void
  reset: () => void
}

interface UseJobTitlesProps {
  searchTerm: string
  initialPage?: number
}

interface ApiResponse<T> {
  results: T[]
  total_matches: number
}

const BASE_URL = process.env.REACT_APP_API_URL

export const api = axios.create({
  baseURL: BASE_URL
})

const initialState: JobTitlesState = {
  alternativeTitles: {
    data: {
      results: [],
      total_matches: 0
    },
    loading: false,
    error: null,
    hasMore: true,
    currentPage: 1
  }
}

const useJobTitles = ({
  searchTerm
}: UseJobTitlesProps): UseJobTitlesReturn => {
  const [state, setState] = useState<JobTitlesState>(initialState)
  const { getAccessTokenSilently } = useAuth0()
  const [lastSearchTerm, setLastSearchTerm] = useState<string>(searchTerm)
  const { handleChangeSelectedJobLoading } = useSelectedJob()

  const fetchData = useCallback(
    async <T>(
      endpoint: string,
      page: number,
      stateKey: keyof JobTitlesState
    ): Promise<ApiResponse<T>> => {
      const token = await getAccessTokenSilently()

      const response = await api.get<ApiResponse<T>>(endpoint, {
        params: {
          search: searchTerm.trim(),
          page
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return response.data
    },
    [searchTerm, getAccessTokenSilently]
  )

  const fetchAlternativeTitles = useCallback(
    async (page: number, isNewSearch: boolean) => {
      setState((prev) => ({
        ...prev,
        alternativeTitles: {
          ...prev.alternativeTitles,
          loading: true,
          error: null,
          data: isNewSearch
            ? prev.alternativeTitles.data
            : prev.alternativeTitles.data
        }
      }))
      handleChangeSelectedJobLoading(true)
      try {
        const responseData = await fetchData<AlternativeJobTitle>(
          'alternative-job-titles',
          page,
          'alternativeTitles'
        )

        setState((prev) => ({
          ...prev,
          alternativeTitles: {
            ...prev.alternativeTitles,
            data: {
              results:
                page === 1 || isNewSearch
                  ? responseData.results
                  : [
                      ...prev.alternativeTitles.data.results,
                      ...responseData.results
                    ],
              total_matches: responseData.total_matches
            },
            hasMore:
              responseData.results.length > 0 &&
              page * responseData.results.length < responseData.total_matches,
            loading: false,
            currentPage: page
          }
        }))
        handleChangeSelectedJobLoading(false)
      } catch (err) {
        setState((prev) => ({
          ...prev,
          alternativeTitles: {
            ...prev.alternativeTitles,
            error: axios.isAxiosError(err)
              ? err.message
              : 'An unknown error occurred',
            loading: false
          }
        }))
        handleChangeSelectedJobLoading(false)
      }
    },
    [fetchData, handleChangeSelectedJobLoading]
  )

  useEffect(() => {
    const isNewSearch = lastSearchTerm !== searchTerm

    if (isNewSearch) {
      setState((prev) => ({
        ...prev,
        alternativeTitles: {
          ...prev.alternativeTitles,
          hasMore: true,
          currentPage: 1
        }
      }))

      setLastSearchTerm(searchTerm)
    }

    Promise.all([fetchAlternativeTitles(1, isNewSearch)])
  }, [searchTerm, fetchAlternativeTitles, lastSearchTerm])

  const loadMoreAlternativeTitles = useCallback(() => {
    if (!state.alternativeTitles.loading && state.alternativeTitles.hasMore) {
      fetchAlternativeTitles(state.alternativeTitles.currentPage + 1, false)
    }
  }, [state.alternativeTitles, fetchAlternativeTitles])

  const reset = useCallback(() => {
    setState(initialState)
    Promise.all([fetchAlternativeTitles(1, true)])
  }, [fetchAlternativeTitles])

  return {
    ...state,
    loadMoreAlternativeTitles,
    reset
  }
}

export default useJobTitles
