import { useState, useEffect, useRef, useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import useJobTitles, {
  type JobTitle,
  type AlternativeJobTitle
} from 'src/hooks/useAlternativeJobTitles'
import { useSelectedJob } from 'src/hooks/useSelectedJob'

export function useJobSearch() {
  const [inputValue, setInputValue] = useState<string>('')
  const [debouncedInputValue] = useDebounce(inputValue, 500)
  const [open, setOpen] = useState<boolean>(false)
  const { setSelectedJob } = useSelectedJob()

  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const { alternativeTitles, loadMoreAlternativeTitles } = useJobTitles({
    searchTerm: debouncedInputValue
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        inputRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const capitalizeEachWord = (str: string) => {
    if (!str) return ''

    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const filteredOptions = useMemo(() => {
    if (!alternativeTitles?.data?.results) return []

    return alternativeTitles.data.results.map((option) => ({
      ...option,
      occ2010_title: capitalizeEachWord(option.occ2010_title!)
    }))
  }, [alternativeTitles.data.results])

  const isJobTitle = (
    item: JobTitle | AlternativeJobTitle
  ): item is JobTitle => {
    return 'total_postings' in item
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    if (!open) setOpen(true)
  }

  const handleOptionClick = (option: JobTitle | AlternativeJobTitle) => {
    if (isJobTitle(option)) {
      setInputValue(capitalizeEachWord(option.occ2010_title))
      setSelectedJob({
        ...option,
        occ2010_title: capitalizeEachWord(option.occ2010_title)
      })
    } else {
      setInputValue(capitalizeEachWord(option.occ2010_title || ''))
      setSelectedJob({
        ...option,
        occ2010_title: capitalizeEachWord(option.occ2010_title || '')
      })
    }
    setOpen(false)
  }

  const selectedOptions = filteredOptions

  return {
    inputValue,
    open,
    inputRef,
    dropdownRef,
    selectedOptions,
    handleInputChange,
    handleOptionClick,
    setOpen,
    loadMoreAlternativeTitles,
    filteredOptions
  }
}
