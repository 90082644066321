import { useSelectedJob } from 'src/hooks/useSelectedJob'
import { JobSearchDropdown } from './components/JobSearchDropdown'
import { JobSearchInput } from './components/JobSearchInput'
import { useJobSearch } from './useJobSearch'

export function JobSearchAutocomplete() {
  const {
    inputValue,
    open,
    inputRef,
    dropdownRef,
    selectedOptions,
    handleInputChange,
    handleOptionClick,
    setOpen,
    loadMoreAlternativeTitles,
    filteredOptions
  } = useJobSearch()
  const { selectedJobLoading } = useSelectedJob()

  const handleLoadMore = async () => {
    await loadMoreAlternativeTitles()
  }

  return (
    <div className='w-full max-w-xl p-4'>
      <div className='relative'>
        <JobSearchInput
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          inputRef={inputRef}
          setOpen={setOpen}
        />
        <JobSearchDropdown
          open={open}
          selectedOptions={selectedOptions}
          handleOptionClick={handleOptionClick}
          inputValue={inputValue}
          loadMoreAlternativeTitles={handleLoadMore}
          isLoading={selectedJobLoading}
          dropdownRef={dropdownRef}
          handleLoadMore={handleLoadMore}
          filteredOptions={filteredOptions}
        />
      </div>
    </div>
  )
}
