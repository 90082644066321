import { useAuth0 } from '@auth0/auth0-react'
import { api } from './useAlternativeJobTitles'

interface JobWorkerResponse {
  id: number
  met2013: string
  occ_code: number
  total_workers: number
  region_code: string
  stars_population: number
  job_title: string
  job_type: string
  geo_type_id: number
}

const useFetchWorkers = () => {
  const { getAccessTokenSilently } = useAuth0()

  async function fetchJobWorkersData(
    regionCode: string | number,
    occCode: number
  ): Promise<JobWorkerResponse> {
    const token = await getAccessTokenSilently()

    try {
      const response = await api.get('/job-workers', {
        params: {
          region_code: regionCode.toString(),
          occ_code: occCode.toString()
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return response.data as JobWorkerResponse
    } catch (error) {
      console.error('Erro to search data from workers', error)
      throw error
    }
  }

  return { fetchJobWorkersData }
}

export default useFetchWorkers
