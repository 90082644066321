import { Grid, LinearProgress, Typography, Paper } from '@mui/material'
import { regionNameFormatting } from '../util/util'
import { useSelectedJob } from 'src/hooks/useSelectedJob'
import { JobSearchAutocomplete } from './JobSearchAutocomplete'

const JobSearchSection = ({ msaSelectedRegion, jobSearchLoading }) => {
  const { jobWorker, fetchWorkersLoading } = useSelectedJob()
  const regionNameString = () => {
    let regionString = `${regionNameFormatting(
      msaSelectedRegion?.region_name,
      msaSelectedRegion?.region_code,
      msaSelectedRegion?.geo_type_id
    )}`
    return regionString
  }

  return (
    <Paper sx={{ padding: 3 }}>
      <Grid container>
        <Grid item sx={{ pb: 3 }}>
          <Typography variant='subheader'>
            Type a job title to explore top skills for the occupation.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <JobSearchAutocomplete />
      </Grid>

      <Grid container>
        <Grid item>
          <Typography
            variant='subheader2'
            style={{ fontWeight: 400, paddingTop: '1rem' }}
          >
            Currently in this job or similar jobs in {regionNameString()}:
            <br />
          </Typography>
          {fetchWorkersLoading ? (
            <div style={{ width: '70%' }}>
              <LinearProgress color='primary' />
            </div>
          ) : (
            <>
              <Typography
                variant='subheader'
                style={{ fontWeight: 700, paddingRight: '2rem' }}
              >
                {Math.round(jobWorker?.total_workers).toLocaleString('en-US')}{' '}
                workers /{' '}
                {Math.round(jobWorker?.stars_population).toLocaleString(
                  'en-US'
                )}{' '}
                STARs
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default JobSearchSection
