import { useState, useContext, createContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getParamsObj } from '../util/util'
import { useAuth0 } from '@auth0/auth0-react'

const api_url = process.env.REACT_APP_API_URL

const MsaRegionSelectionContext = createContext()

export function MsaRegionSelectionProvider({ children }) {
  const msaRegionSelection = useProvideMsaRegionSelection()
  return (
    <MsaRegionSelectionContext.Provider value={msaRegionSelection}>
      {children}
    </MsaRegionSelectionContext.Provider>
  )
}

export function useMsaRegionSelection() {
  return useContext(MsaRegionSelectionContext)
}

function useProvideMsaRegionSelection() {
  const [msaSelectedRegion, setMsaSelectedRegion] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()
  const [jobSelection, setJobSelection] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [jobSearchLoading, setJobSearchLoading] = useState(true)
  const [jobOptions, setJobOptions] = useState(null)
  const { getAccessTokenSilently } = useAuth0()

  const handleMsaRegionSelection = (
    selected_region,
    { updateParams = true } = {}
  ) => {
    setMsaSelectedRegion(selected_region)

    if (updateParams) {
      setSearchParams({
        ...getParamsObj(searchParams, [
          'region',
          'job_title',
          'geo_type_id',
          'region_code'
        ]),
        region: selected_region.region_name.toLowerCase(),
        geo_type_id: selected_region.geo_type_id,
        region_code: selected_region.region_code
      })

      window.dataLayer.push({ event: 'Page Loaded' })
    }

    // get the jobs for the region
    setJobSearchLoading(true)
    const msa_region_code = selected_region?.region_code

    getAccessTokenSilently().then((accessToken) => {
      fetch(`${api_url}/2.0?region_code=${msa_region_code}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
        .then((resp) => resp.json())
        .then((jobOptionObjects) => {
          setJobOptions(
            jobOptionObjects.sort((a, b) =>
              a.job_title > b.job_title ? 1 : -1
            )
          )

          // now set jobSelection
          const jobOptionTitles = jobOptionObjects.map((joo) => joo.job_title)

          let searchedJobTitle = jobSelection?.job_title.toLowerCase()

          let foundJob = jobOptionObjects?.find(
            (job) => job.job_title.toLowerCase() === searchedJobTitle
          )
          if (foundJob) {
            setJobSelection(foundJob)

          } else if (
            !jobSelection ||
            !jobOptionTitles.includes(jobSelection?.job_title)
          ) {
            // if there is not a current jobSelection or there is but it's not in the newly acquired list, then set it to the first item in the list.
            // calls setter directly rather than handleJobSelection because we do not want to update job param, just state variable
            setJobSelection(jobOptionObjects[0])

          }
          // otherwise, we have an exising valid jobSelection and there is nothing to update

          setJobSearchLoading(false)
        })
    })

    setIsLoading(false)
  }

  return {
    msaSelectedRegion,
    handleMsaRegionSelection,
    isLoading,
    jobSearchLoading,
    jobOptions,
    jobSelection,
    setJobSelection
  }
}
