import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CloseIcon from '@mui/icons-material/Close'

export function JobSearchInput({
  inputValue,
  handleInputChange,
  inputRef,
  setOpen
}: any) {
  return (
    <div className='relative flex items-center'>
      <input
        ref={inputRef}
        type='text'
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setOpen(true)}
        className='w-full px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-black pr-10'
        placeholder='Search...'
      />

      {inputValue ? (
        <CloseIcon
          sx={{
            fontSize: 20,
            position: 'absolute',
            right: '2.5rem',
            top: '50%',
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            color: '#9ca3af'
          }}
          onClick={() => handleInputChange({ target: { value: '' } })}
        />
      ) : null}

      <ExpandMoreIcon
        sx={{
          fontSize: 20,
          position: 'absolute',
          right: '0.75rem',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#9ca3af'
        }}
      />
    </div>
  )
}
